
import '../styles/index.less';
import PerfectScrollbar from 'perfect-scrollbar';
import fastclick from 'fastclick';
fastclick.attach(document.body);

// const ps = new PerfectScrollbar('#message-content');

// function setMessgeContentHeight() {
//    var w_height = $(document).height();
//    var header_height = $('.top').height()
//    var bottom_height = $('.dialogue-footer').height()
//    $('#message-content').height(w_height - header_height - bottom_height )
//    console.log(w_height, header_height, bottom_height)
//    ps.update();
// }
// setMessgeContentHeight()
// $(window).resize(function () {
// setMessgeContentHeight()
// });

window.sktwdfun = {
    parseURL: function(url) {
        var a = document.createElement("a");
        a.href = url;
        return {
            host: a.hostname,
            params: (function() {
                var ret = {},
                    seg = a.search.replace(/^\?/, "").split("&"),
                    len = seg.length,
                    i = 0,
                    s;
                for (; i < len; i++) {
                    if (!seg[i]) { continue } s = seg[i].split("=");
                    ret[s[0]] = s[1]
                }
                return ret
            })()
        }
    },
    post: function(url, data, fn, is_as) {
        var obj = new XMLHttpRequest();
        obj.open("POST", url, is_as);
        obj.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        obj.onreadystatechange = function() { if (obj.readyState == 4 && (obj.status == 200 || obj.status == 304)) { fn.call(this, obj.responseText) } };
        obj.send(data)
    }
};


$('.tel').attr('href', 'tel:'+config.tel)
var refer = document.referrer;
var referurl = sktwdfun.parseURL(refer);
var wd = referurl.params.wd || referurl.params.word || referurl.params.query || referurl.params.query || referurl.params.keyword || referurl.params.word1 || referurl.params.title || "";

if (wd.length > 0) { wd = decodeURIComponent(wd); if (wd.indexOf("%") >= 0) wd = decodeURIComponent(wd) }

if (config.searchword == 1 && wd.length > 0) {
    setTimeout(function() { jgShowClientMsg(wd) }, 500)
}

var ydy1 = config.ydy1.replace("[key]", wd);
var ydy2 = config.ydy2.replace("[key]", wd);
var ydy3 = config.ydy3.replace("[key]", wd);
var ydy4 = config.ydy4.replace("[key]", wd);
var today = new Date().getHours();
var jgJump = config.jgJump;

if (config.yejian == 1 && config.dayHour != 0 && config.nightHour != 0) {
    if (today >= config.dayHour && today <= config.nightHour) {} else {
        location.href = config.yejianUrl
    }
}

if (config.header_ad == 1) {
    $('.top_banner').show();
}
if (config.mainTop_topadimg == 1) {
    $('.swiper-banners').show();
}

var hidesec = 5000;
if (config.shbydy1 == "1") {
    hidesec = config.ydy1sec * 1000;
    setTimeout(function() {
        jgShowAgentMsg(ydy1, 'messages')
    }, hidesec)
};
if (config.shbydy2 == "1") {
    $("#message_2 .msg").html(ydy2);
    hidesec = config.ydy2sec * 1000;
    setTimeout(function() {
        jgShowAgentMsg(ydy2, 'messages')
    }, hidesec)
};
if (config.shbydy3 == "1") {
    $("#message_3 .msg").html(ydy3);
    hidesec = config.ydy3sec * 1000;
    setTimeout(function() {
        jgShowAgentMsg(ydy3, 'messages')
    }, hidesec)
};

if (config.shbydy4 == "1") {
    $("#message_4 .msg").html(ydy4);
    hidesec = config.ydy4sec * 1000;
    setTimeout(function() {
        jgShowAgentMsg(ydy4, 'messages')
    }, hidesec)
};


function LR_checkagent(_lr_na) { var _lr_o = _lr_na.split("|"); for (_lr_w = 0; _lr_w < _lr_o.length; _lr_w++) { if (navigator.userAgent.toLowerCase().indexOf(_lr_o[_lr_w]) > -1) return true } return false };
var is_ios = LR_checkagent("ipad|iphone|ipod");
var is_baidu = LR_checkagent("baiduboxapp");

if ((config.bdTjId + "").length > 1) {
    var _hmt = _hmt || [];
    (function() {
        var hm = document.createElement("script");
        hm.src = "//hm.baidu.com/hm.js?" + config.bdTjId;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s)
    })()
};


function LR_CheckImgJS(w, w1, w2, w3) {
    LR_inviteimgJS = 1;
    if (!w) { return }
    if (w == 28) { return }
    if (checkcount == -1 && w != 6) return;
    if (w > 0) {
        if (w == 2) {
            LR_gstate = 1;
            if (LR_istate < 2) {
                clearinviteTimeout();
                LR_istate = 2;
                window.focus();
                sendMessage();
                return
            }
        }
        if (w == 8) {
            LR_gstate = 1;
            if (LR_istate > -1) {
                clearinviteTimeout();
                LR_istate = 1;
                sendMessage()
            }
            return
        }
        if (w == 4) {
            LR_gstate = 0;
            if (LR_istate > 1) { LR_istate = 0 } LR_RefuseChat();
            LR_HideInvite();
            return
        }
        if (w == 5) {
            clearinviteTimeout();
            if (LR_istate > 0) { LR_istate = 0 }
            if (LR_gstate != 2) {
                LR_gstate = 2;
                LR_SetCookie("LR_lastchat", "1", 720)
            }
            if (LR_GetObj("LRMINIBar") == null && ((LR_pm001 && LR_pm002) || LR_pm009)) {
                LR_HideInvite();
                LR_istate = 1
            }
            return
        }
        if (w == 7) {
            LR_gstate = -1;
            if (LR_istate > 1) { LR_istate = 0 } LiveReceptionCode_isonline = 0;
            if (typeof(LR_offline) == "function") LR_offline();
            LR_Floaters[0].pms["html"] = LR_buildfloat();
            LR_Floaters[0].imageTimer(true);
            return
        }
        if (w == 1) {
            LR_RefuseChat();
            LR_HideInvite();
            if (LR_gstate == 1) return
        }
    }
};

var firstmes = "",
    jgLocalStorage = !0,
    jgChatAutoReplyCnt = 0,
    jgAgentMsgCnt = 0,
    jgClientMsgCnt = 0,
    jgAuth = !1,
    jgShowNewMsgIngStatus = !1,
    jgRemindCnt = 0,
    jgRemindTimer, jgAgentName;
if ("object" === typeof localStorage) try {
    localStorage.setItem("localStorage", 1), localStorage.removeItem("localStorage")
} catch (a) {
    jgLocalStorage = !1
};

function LR_showminiDiv(a) {
    a || (a = !1);
    LR_pm010 && (a = !0);
    LR_MCount1 = 2E4;
    "" == LR_Floaters[3].pms.html && (LR_Floaters[3].pms.html = minibarhtml(), LR_Floaters[3].imageTimer(!0), document.body && document.body.appendChild(LR_GetObj("LRdiv3")), LR_Floaters[3].showdiv(0));
    LR_GetObj("LRdiv2").style.display = LR_pm007 ? "block" : "none";
    LR_pm007 || lrminiMinNext();
    LR_GetObj("LRMINIBar").style.display = !LR_pm007 || a ? "block" : "none";
    "" == LR_Floaters[2].pms.html ? (LR_Floaters[2].pms.html = minichathtml(), LR_Floaters[2].imageTimer(!0), document.body && document.body.appendChild(LR_GetObj("LRdiv2")), (LR_GetObj("LRfloater2").style.position = "absolute"), setTimeout("LR_miniframe.location=LR_sysurl + 'LR/MiniChat_M.aspx?id=' + LR_websiteid + '&cid=' + LR_cid + '&lng=cn&sid=' + LR_sid+'&e='+escape('首客通对话') + '&msg='+escape(LR_msg) + '&p=' + escape(location.href) + '&r=' + lr_refer5237", 100), LR_pm007 && !a && LR_Floaters[2].showdiv(0), LR_miniH()) : (LR_Floaters[0].hidden(), LR_HideInvite())
};

function jgPageCleanUp() {
    if (jgLocalStorage) {
        var a = localStorage.getItem("jgChatTime");
        localStorage.getItem("jgClientMsgCnt") && !a && localStorage.clear();
        var b = (new Date).getTime();
        a && 6E5 < b - a && localStorage.clear();
        localStorage.setItem("jgChatTime", b)
    }
};

function jgHideMini() {
    document.querySelector("#LRdiv2") && (document.querySelector("#LRdiv2").style.display = "none");
    document.querySelector("#LRfloater2") && (document.querySelector("#LRfloater2").style.display = "none");
    document.querySelector("#LRdiv3") && (document.querySelector("#LRdiv3").style.display = "none");
    document.querySelector("#LRfloater3") && (document.querySelector("#LRfloater3").style.display = "none");
    document.querySelector("#LRMINIBar") && (document.querySelector("#LRMINIBar").style.display = "none");
    document.querySelector("#LRMINIBar1") && (document.querySelector("#LRMINIBar1").style.display = "none")
};

function jgShowPreMsg() {
    if (jgLocalStorage && config.ignoreWelcome) {
        var a = config.ignoreWelcome;
        if (localStorage.getItem("a" + a)) {
            a = JSON.parse(localStorage.getItem("a" + a));
            for (var b in a)
                if (localStorage.getItem("c" + a[b])) {
                    var c = localStorage.getItem("c" + a[b]);
                    jgShowClientMsg(c)
                }
        }
    }
};
jgPageCleanUp();
jgClientMsgCnt = jgAgentMsgCnt = 0;
if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) && /baiduboxapp/i.test(navigator.userAgent)) jgAgentMsgCnt = 1;
var jgStart = 0,
    isauth = 0,
    issend = 0,
    tempfirstmes = "";
window.addEventListener("message", function(a) {
    a = a.data;
    if ("auth" == a.a) {
        if (firstmes.length > 0) {
            tempfirstmes = firstmes;
            firstmes = "";
            setTimeout(function() {
                var b = {
                    a: "jgHint"
                };
                b.b = "," + tempfirstmes;
                window.frames.LR_miniframe.postMessage(b, "*");
            }, 500);
        };
        isauth = 1;
        if (config.ShowAgentRemind == 1) setTimeout(function() {
            window.frames.LR_miniframe.postMessage({
                a: "jgHint",
                b: "客户进入页面5秒啦，给他发个消息呗"
            }, "*")
        }, 5E3);
    }
    if ("hide" == a.a) lrminiMin();
    else if ("tel" == a.a) {
        var b = document.createElement("DIV");
        b.id = "LR_tel_" + (new Date).getTime();
        if(b.style) display = "none";
        b.innerHTML = '<a href="tel:' + a.b + '" id="' + b.id + 'href" style="display:none;"></a>';
        document.body.insertBefore(b, document.body.firstChild);
        LR_GetObj(b.id + "href").click()
    } else if ("maxme" == a.a) openZoosUrl("fchatwin");
    else if ("setfocus" == a.a) LR_minifocus = a.b;
    else if ("zhendong" == a.a) {
        if (LR_checkagent("android")) try {
            navigator.vibrate(500)
        } catch (e) {}
    } else if ("scroll" == a.a) LR_html_height = document.documentElement.style.height, LR_html_overflow = document.documentElement.style.overflow, LR_body_height = document.body.style.height, LR_body_overflow = document.body.style.overflow, document.documentElement.style.height = document.body.style.height = "100%", document.documentElement.style.overflow = document.body.style.overflow = "hidden";
    else if ("scroll3" == a.a) "none" != LR_GetObj("LRMINIWIN").style.display && (LR_miniH(a.b), window.scrollTo(0, 9999));
    else if ("scroll2" == a.a) document.body.style.height = LR_body_height, document.body.style.overflow = LR_body_overflow, document.documentElement.style.height = LR_html_height, document.documentElement.style.overflow = LR_html_overflow, LR_miniH();
    else if ("scroll1" == a.a) a = {
        a: "scroll1"
    }, a.b = LR_scrollTop() + LR_geth() - LR_scrollHeight(), a.c = LR_geth(), window.frames.LR_miniframe.postMessage(a, "*");
    else if ("newmsg" == a.a && "none" == LR_GetObj("LRdiv2").style.display)
        if (jgRemindCnt = 0, !jgAgentName && a.d && a.d.match(/\d{8,}/) && (jgAgentName = a.d.replace(/(\d{8,})/, "<a class='zxdh' href='tel:$1'>$1</a>"), document.querySelector("#jgAgentTitle") && (document.querySelector("#jgAgentTitle").innerHTML = jgAgentName)), document.querySelector("#jgNewMsgIng") && (document.querySelector("#jgNewMsgIng").style.display = "none", jgShowNewMsgIngStatus = !1), jgAgentMsgCnt++, config.ignoreWelcome && 0 < config.ignoreWelcome && config.ignoreWelcome >= jgAgentMsgCnt) {
            if (config.ShowAgentRemind == 1) window.frames.LR_miniframe.postMessage({
                a: "jgHint",
                b: "客户进入页面5秒啦，给他发个消息呗"
            }, "*");
        } else {
            jgChatAutoReplyCnt = 0;
            if ((config.ignoreWelcome == 0 && jgAgentMsgCnt > 1) || (config.ignoreWelcome == 1 && jgAgentMsgCnt > 2)) {
                jgShowAgentMsg(a.b, "messages");
                jgShowAgentMsg(a.b, "jgChatInNotice");
                jgScrollPage();
                setTimeout(jgScrollPage, 200);
            };
            jgAgentMsgCnt++;
            if (jgLocalStorage && localStorage.getItem("a" + jgAgentMsgCnt)) {
                var c = JSON.parse(localStorage.getItem("a" + jgAgentMsgCnt));
                for (b in c)
                    if (localStorage.getItem("c" + c[b])) {
                        var d = localStorage.getItem("c" + c[b]);
                        jgShowClientMsg(d)
                    }
            };
            if (0 <= a.b.indexOf("再次请求对话") || 0 <= a.b.indexOf("重新打开本对话窗口")) {
                // document.querySelector("#jgRechat").style.display = "block";
                // document.querySelector("#dialogue-bar").style.display = "none",;
                $('#dialogue-bar').hide()
                $('#chat-bar').show();
                jgLocalStorage && localStorage.clear()
            }
        }
}, !1);

function jgEmptySpace() {
    var a = document.createElement("span");
    a.style.display = "none";
    a.id = "jgEmptySpace";
    document.body.appendChild(a)
};

function jgShowClientMsg(a) {
    if (document.querySelector("#messages")) {
        let html = '<div class="imlp-component-chatviewbase row-reverse-flex ">'+
                            '<div class="imlp-component-chatviewbase-avatar-container">'+
                                '<div class="imlp-component-chatviewbase-avatar" style="background-image: url('+config.clientAvatar+'); margin: 0px 17px 0px 8px;">'+
                               ' </div>'+
                            '</div>'+
                            '<div class="imlp-component-chatviewbase-bubble-container row-reverse-flex">'+
                                '<div class="imlp-component-chatviewbase-bubble right-chatviewbase-bubble">'+
                                    '<div>'+
                                        '<div class="imlp-component-text">'+a+'</div>'+
                                    '</div>'+
                                '</div>'+
                            '</div>'+
                        '</div>';
        $('#messages').append(html)
        jgScrollPage();
        setTimeout(jgScrollPage, 200)
    }
};

function jgShowNewMsgIng() {
    jgShowNewMsgIngStatus && document.querySelector("#jgNewMsgIng") && (document.querySelector("#jgNewMsgIng").style.display = "block", jgScrollPage(), setTimeout(jgHideNewMsgIng, 15E3 + 5E3 * Math.random()))
};

function jgHideNewMsgIng() {
    jgShowNewMsgIngStatus && document.querySelector("#jgNewMsgIng") && (document.querySelector("#jgNewMsgIng").style.display = "none", setTimeout(jgShowNewMsgIng, 5E3 + 5E3 * Math.random()))
};

function jgAgentRemind() {
    jgRemindCnt++;
    window.frames.LR_miniframe.postMessage({
        a: "jgHint",
        b: "患者还在聊天窗口，等待" + 5 * jgRemindCnt + "秒"
    }, "*")
};

function jgChatAutoReply(a) {};
var jgHideMiniCnt = 0,
    jgHideMiniTimer = setInterval(function() {
        jgHideMini();
        jgHideMiniCnt++;
        100 < jgHideMiniCnt && clearInterval(jgHideMiniTimer)
    }, 300);
jgEmptySpace();

function sendMessage() {
    jgShowNewMsgIngStatus = !0;
    var a = document.querySelector("#chat-txt").value.replace(/\s/g, "");
    document.querySelector("#chat-txt").value = "";
    if (jgStart == 0) {
        if (/^\d{2}$/.test(a)) a = a + "岁";
        window.LR_msg = a;
        if (document.querySelector("#LRMINIBar")) {
            jgHideMini();
            jgStart = 1;
            if (a.length > 0 && issend == 0) {
                var b = {
                    a: "jgHint"
                };
                b.b = "," + a;
                window.frames.LR_miniframe.postMessage(b, "*");
                jgShowClientMsg(a);
                issend = 1;
                firstmes = a;
            }
        } else {
            LR_showminiDiv();
            lrminiMin();
            jgHideMini();
            jgShowPreMsg();
            jgStart = 1;
            if (a.length > 0 && issend == 0) {
                jgShowClientMsg(a);
                issend = 1;
                firstmes = a;
            }
        }
        // document.querySelector("#chatjs").parentElement.removeChild(document.querySelector("#chatjs"));
    } else {
        if (a.length > 0) {
            var b = {
                a: "jgHint"
            };
            b.b = "," + a;
            window.frames.LR_miniframe.postMessage(b, "*");
            jgShowClientMsg(a);
        }
    }
    if (0 != a.length) {
        if (config.ShowAgentRemind == 1) jgRemindTimer = setInterval(jgAgentRemind, 5E3);
        0 == jgClientMsgCnt && 10 > a.length && (jgChatAutoReply(a), jgChatAutoReplyCnt = 1);
        document.querySelector("#chat-txt").blur();
        setTimeout(function() {
            var a = document.querySelector("#jgNewMsgIng");
            if (a) {
                var b = document.querySelector("#jgNewMsgIng").parentElement;
                b.removeChild(a);
                b.appendChild(a);
                jgShowNewMsgIng()
            }
        }, 4E3);
        "undefined" != typeof jgChatTrackPackage && (jgChatTrackPara = "id=" + jgChatTrackPackage.id + "&chat=c:" + a + "&act=" + jgChatTrackPackage.act, jgChatTrackPulse(jgChatTrackPara));
        jgLocalStorage && (jgClientMsgCnt = (jgClientMsgCnt = localStorage.getItem("jgClientMsgCnt")) ? parseInt(jgClientMsgCnt) : 0, jgClientMsgCnt++, localStorage.setItem("jgClientMsgCnt", jgClientMsgCnt), localStorage.setItem("c" + jgClientMsgCnt, a), a = Math.max(config.ignoreWelcome, jgAgentMsgCnt), localStorage.getItem("a" + a) ? (b = JSON.parse(localStorage.getItem("a" + a)), b.push(jgClientMsgCnt), localStorage.setItem("a" + a, JSON.stringify(b))) : localStorage.setItem("a" + a, JSON.stringify([jgClientMsgCnt])))
        jgScrollPage();
    }
    jgScrollPage();
};

function jgShowAgentMsg(a, b) {
    if (document.querySelector("#" + b)) {
        let html = '<div class="imlp-component-chatviewbase row-flex ">'+
                            '<div class="imlp-component-chatviewbase-avatar-container">'+
                                '<div class="imlp-component-chatviewbase-avatar" style="background-image: url('+config.doctorAvatar+'); margin: 0px 8px 0px 17px;">'+
                                '</div>'+
                            '</div>'+
                            '<div class="imlp-component-chatviewbase-bubble-container row-flex">'+
                                '<div class="imlp-component-chatviewbase-bubble left-chatviewbase-bubble">'+
                                    '<div>'+
                                        '<div class="imlp-component-text">'+a+'</div>'+
                                    '</div>'+
                                '</div>'+
                            '</div>'+
                        '</div>';
        $('#messages').append(html)
        jgScrollPage();
    }
};

function jgScrollPage() {
    // console.log($('#message-content').prop("scrollHeight"))
    $('#messages,#message-content, #app, html, body, .imlp-component-chatview ').stop().animate({
      'scrollTop': 99999
    }, 900, 'swing');
    // ps.update()
};
document.querySelector("#jgChatSendBut") && (document.querySelector("#jgChatSendBut").onclick = sendMessage);
document.querySelector("#chat-txt") && (document.querySelector("#chat-txt").addEventListener("focus", function(a) {
    jgHideMini();
    // setTimeout(jgScrollPage, 200)
}), document.querySelector("#chat-txt").addEventListener("blur", function(a) {
    // setTimeout(jgScrollPage, 200)
}), document.querySelector("#chat-txt").addEventListener("keyup", function(a) {
    // setTimeout(jgScrollPage, 200);
    0 < this.value.length && (clearInterval(jgRemindTimer), jgRemindCnt = 0, jgShowNewMsgIngStatus = !1, jgHideNewMsgIng(), isauth == 1 && (a = {
        a: "jgHint",
        b: this.value.replace(/,/, "，")
    }, window.frames.LR_miniframe.postMessage(a, "*")))
}));
document.querySelector("#rechat") && document.querySelector("#rechat").addEventListener("click", function(a) {
    a.preventDefault();
    window.location.reload()
});


$('#sendMessage').on('click', function() {
    sendMessage()
})

$('.chat-txt').keypress(function(event) {
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode == '13') {
        sendMessage()
    }
});



$('.swt').on('click', function() {
    openZoosUrl();return false;
})
